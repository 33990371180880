<template lang="pug">
.brand-wrapper
  .container
    .row
      .col-6.mx-auto
        h1.text-center {{ getWelcomeTitle(loginInfo) }}!
    .row.pt-3
      .col-6.mx-auto
        .text-center {{ $t('selectAnAccount') }}&nbsp
          span.color-brand-primary.pointer(@click="signOut") {{ $t('orSignOut') }}.
    .row.pt-4
      .col-6.col-lg-4.mx-auto
        ul.list-group.brand-list-group
          li.list-group-item.pr-5(v-for="account in sharedAccounts" @click="login(account)")
            strong.brand-account-name {{ account.name }} ({{ accountType(account.type) }})
            br
            span.brand-account-campaigns {{ account.campaignInfos.campaignCount }} {{ $t('campaign') }}
</template>

<script>
  import CREATE_LOGIN_CREDENTIALS from '@/graphql/CreateLoginCredentials.gql';
  import LOGOUT from '@/graphql/Logout.gql';
  import fullName from '@/mixins/fullName';
  import { mapActions, mapState, mapGetters } from 'vuex';
  import {
    createCampaignAndRedirectToEditor,
    copyCampaignAndRedirectToEditor,
  } from '@/router/utils';
  import { getHeap } from '@/services/xray';
  import { setAccountIdInCookie } from '../util';

  export default {
    mixins: [fullName],

    beforeRouteEnter(to, from, next) {
      next((vm) => {
        if (from.name === 'oauth_login') {
          vm.cameFromOAuthLogin = true;
        }
      });
    },
    data() {
      return {
        loginInfo: {},
        cameFromOAuthLogin: false,
      };
    },

    computed: {
      ...mapState(['sharedAccounts']),
      ...mapGetters(['accountFeatures']),
    },

    async created() {
      this.loginInfo = await this.fetchSharedAccounts();
    },

    methods: {
      ...mapActions(['fetchSharedAccounts']),

      accountType(type) {
        if (['sub', 'normal', 'agency'].indexOf(type) === -1) {
          type = 'normal';
        }

        return this.$t(`switchAccount.${type}`);
      },

      login(account) {
        this.$apollo
          .mutate({
            mutation: CREATE_LOGIN_CREDENTIALS,
            variables: { databaseId: account.databaseId },
          })
          .then(async ({ data }) => {
            this.$router.replace({ name: 'account-switching' });

            const login = data.login;
            const userId = login.dataAccountId;
            setAccountIdInCookie(userId);
            getHeap()?.identify?.(login.dataAccountId);
            if (login.redirectUrl) {
              window.location = login.redirectUrl;
            } else {
              await this.$store.dispatch('login', {
                expiration: login.expiration,
                accountType: account.type,
                locale: login.locale,
                impersonate: login.impersonate,
              });
              const redirectAfterSwitchAccount = localStorage.getItem(
                'om_redirect_after_switch_account',
              );
              sessionStorage.removeItem('campaignFilter');
              if (redirectAfterSwitchAccount) {
                localStorage.removeItem('om_redirect_after_switch_account');
                return this.$router.replace({ path: redirectAfterSwitchAccount });
              }

              if (this.cameFromOAuthLogin) {
                this.$router.replace({ name: 'oauth_login', query: this.$route.query });
              } else {
                const isCreate = await createCampaignAndRedirectToEditor();
                const isCopy = await copyCampaignAndRedirectToEditor();
                if (isCreate || isCopy) return;
                this.$router.replace({ name: 'dashboard', params: { userId } });
              }
            }
          });
      },

      async signOut() {
        await this.$apollo.mutate({
          mutation: LOGOUT,
        });
        if (this.cameFromOAuthLogin) {
          this.$store.commit('logout', false);
          this.$router.push({ name: 'oauth_login', query: this.$route.query });
        } else {
          this.$store.commit('logout');
        }
      },

      getWelcomeTitle(login) {
        if (!login) {
          return this.$t('welcome');
        }
        return this.$t('welcomeUser', {
          firstName: this.getFullNameByLocale(login.firstName, login.lastName, login.locale),
        });
      },
    },
  };
</script>
